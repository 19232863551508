<template>
  <div class="tzhh">
    <div class="header">
      <div class="h1">投资黄骅</div>
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item to="/tzhh" >投资黄骅</el-breadcrumb-item>
        <el-breadcrumb-item>直播中心</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="margin: 100px 0" class="flex">
        <div style="852px;height: 480px">
          <video rel="videoPlay"  style="852px;height: 480px" ref="videoPlay" :poster="categoryList[number].cover"  muted loop class="video" controls="controls">
            <source  :src="categoryList[number].file" type="video/mp4">
          </video>
        </div>
        <div style="margin-left: 20px;">
          <div style="margin-bottom: 20px">
            <el-button :type="number==index?'primary':''"  v-for="(li,index) in categoryList" @click="tab(index)" :key="index">{{li.title}}</el-button>
          </div>
          <el-timeline style="height: 420px;overflow: auto;padding: 0 20px">
            <el-timeline-item  :color="index==number?'#66b1ff':''" v-for="(li,index) in arr" :key="index" timestamp="2024/4/12" placement="top">
              <el-card>
                <h4>{{li.title}}</h4>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import {broadcastcenterlist} from "../../request/moudle/tzhh";

export default {
  name: "index",
  data(){
    return {
      number:0,
      categoryList:[],
      arr:[],
      page:{
        current:1,
        size:20,
        total:0
      },
    }
  },
  mounted() {
    this.loadconfig()
  },
  methods:{
    tab(index){
      this.number=index
      this.loadlist()
    },
    loadconfig(){
      this.$api.tzhh.broadcastcenterindex().then((res)=>{
        this.categoryList=res.data.categoryList
        this.loadlist()
      })
    },
    loadlist(bol){
      if(bol){
        this.page.current=1
      }
      const obj={}
      obj.current=this.page.current
      obj.size=this.page.size
      obj.categoryId=this.categoryList[this.number].id
      this.$api.tzhh.broadcastcenterlist(obj).then((res)=>{
        this.arr=res.data.records
        this.page.total=res.data.total
        this.$nextTick(()=>{
            this.$refs.videoPlay.src= this.arr[this.number].file;
            this.$refs.videoPlay.poster= this.arr[this.number].cover;
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  background: #fff;
  width: 1200px;
  margin: 20px auto;
}
.tzhh {
  background: #fff;
  .header {
    height: 220px;
    background: url("../../assets/banner/tzhh.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 90px;
    color: #fff;
    .h1 {
      font-size: 42px;
      font-weight: 500;
      margin-top: 50px;
      margin-left: 350px;
    }
  }
}
</style>